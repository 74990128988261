// @see: https://fullcalendar.io/docs/react
// @see: https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx

import React from 'react'
import { injectIntl } from "gatsby-plugin-intl"
import axios from "axios"
import Link from './../Link'

import FullCalendar, { formatDate } from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { 
  // INITIAL_EVENTS, 
  createEventId 
} from './event-utils'

import localePl from '@fullcalendar/core/locales/pl';
import localeEn from '@fullcalendar/core/locales/en-gb';
// import './main.scss'

// import moment from 'moment'


class Component extends React.Component {

  state = {
    loading: false,
    weekendsVisible: false,
    currentEvents: [],
    event_types: {},
  }

  constructor(props) {
    super(props);

    const { intl } = props;
    const t = intl.formatMessage;

    this.intl = intl;
  }

  componentDidMount() {
    this.getEvents();
  }

/*
  async getDataFromPaginatedApi() {
  // get responses from paginated API and build merged response result
  // @see: https://gist.githubusercontent.com/luispinto23/8ca51380044b3b8e7b75a27b7505c0df/raw/067502c8245a1ce4bb8c3cb958cde01c5ee2af06/fetch-paginated-api.js
  let response = null;
  let reviews_json = null;
  let mergedResponsesResults = {};
  let keepGoing = true;
  let page = 0;
  let from = 0;
  let to = limit; 
  let REVIEWS_ENDPOINT = null;
  try {
    while (keepGoing) {
      from = page * limit;
      to = page * limit + limit;
      REVIEWS_ENDPOINT = `${apiUrl}/api/opinion/${serviceId}/${from}/${to}/${sortBy}/${sortDirection}`;
      if (krakowbookingCom_language) {
        REVIEWS_ENDPOINT = `${REVIEWS_ENDPOINT}/${krakowbookingCom_language}`;
      }
// console.log(`REVIEWS_ENDPOINT: `, REVIEWS_ENDPOINT);
      response = await fetch(REVIEWS_ENDPOINT);
      reviews_json = await response.json();
  
      mergedResponsesResults = { 
        ...mergedResponsesResults,
        ...reviews_json,
      };
  
      page += 1;
// console.log(`getReviewsWithoutMetadata(mergedResponsesResults): `, getReviewsWithoutMetadata(mergedResponsesResults));
      // if (Object.values(reviews_json).length < limit) {
      if (
        Object.values(getReviewsWithoutMetadata(mergedResponsesResults)).length === Number(reviews_json.count_opinion)
        || reviews_json.error
      ) {
        keepGoing = false;
      }
    }
  } catch(error) {
console.log(error);
  }
// console.log(`mergedResponsesResults: `, mergedResponsesResults);
// console.log(`mergedResponsesResults: `, JSON.stringify(mergedResponsesResults, null, 4));
  }
*/

  getEvents = async () => {
    const intl = this.intl;

    this.setState({ loading: true });

    const { data: event_types } = await axios.get(`${process.env.SOFT4NET_SOURCE_BASE_URL}/soft4net/field_storage_config_paragraph_field_type`);

    await this.setState({ event_types: event_types });
// console.log(this.state.event_types)

    axios
      .get(
        `${process.env.SOFT4NET_SOURCE_BASE_URL}/${intl.locale}/jsonapi/node/harmonogram?include=field_lectures`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      })
      .then(response => {
// console.log(response);
        const json = response.data;
// console.log(`json: `, json);

        const events = [];
        json.data.forEach(harmonogram => {
          const lectures = harmonogram.relationships.field_lectures.data;
          lectures.forEach(harmonogramLectureMetadata => {
            const harmonogramLectures = json.included.filter(lecture => lecture.id === harmonogramLectureMetadata.id);
            if (!harmonogramLectures.length) {
              return;
            }

            const harmonogramLectureFulldata = harmonogramLectures[0];

            let eventType = null;
            let et = null;
            let fieldTypeAllowedValue = null;
            let nameAndColor = null;

            // const startTime = moment(harmonogramLectureFulldata.attributes.field_date_from);
            // const endTime = moment(harmonogramLectureFulldata.attributes.field_date_to);
            // const duration = moment.duration(endTime.diff(startTime));
            // const hours = parseInt(duration.asHours());
            // // const minutes = parseInt(duration.asMinutes()) % 60;
            // let isAllDay = hours >= 24 ? true : false;
            let isAllDay = false;

// console.log(`harmonogram.attributes.title: `, harmonogram.attributes.title);

            if (`CALENDAR_CUSTOM_EVENTS` === harmonogram.attributes.title) {
              fieldTypeAllowedValue = this.state.event_types[harmonogramLectureFulldata.attributes.field_type];
              nameAndColor = fieldTypeAllowedValue.split(`_`);

              et = {
                type_id: harmonogramLectureFulldata.attributes.field_type,
                type_title: nameAndColor[0],
                classNames: `custom-event-${harmonogramLectureFulldata.attributes.field_type}`,
                color: nameAndColor[1],
                allDay: isAllDay,
              }

              eventType = {
                ...et,
                title: harmonogramLectureFulldata.attributes.field_title,
                url: harmonogramLectureFulldata.attributes.field_url ? harmonogramLectureFulldata.attributes.field_url : et.url,
              }
            } else {
              fieldTypeAllowedValue = this.state.event_types[`harmonogram`];
              nameAndColor = fieldTypeAllowedValue.split(`_`);

              et = {
                type_id: `harmonogram`,
                type_title: nameAndColor[0],
                classNames: `harmonogram`,
                color: nameAndColor[1],
                allDay: isAllDay,
              }

              eventType = {
                ...et,
                title: harmonogram.attributes.title,
                url: harmonogram.attributes.path.alias,
              }
            }

// console.log(nameAndColor)

            // const title = eventType.title.length > 4 ? '' : eventType.title;
            const title = eventType.title;

// if (`TRENER - Szkolenie poziom podstawowy Certified International Professional Trainer - Edycja XVIII` === title) {
//   console.log(`harmonogramLectureFulldata: `, harmonogramLectureFulldata);
//   console.log(`hours: `, hours);
// }
// if (`NEGOCJATOR - Certified International Professional Negotiator® - Edycja XII` === title) {
//   console.log(`harmonogramLectureFulldata: `, harmonogramLectureFulldata);
// }
            // @see: https://fullcalendar.io/docs/event-object
            events.push({
              id: harmonogramLectureFulldata.id,
              start: harmonogramLectureFulldata.attributes.field_date_from, // new Date().toISOString().replace(/T.*$/, '')
              end: harmonogramLectureFulldata.attributes.field_date_to,
              title: title,
              url: eventType.url,
              classNames: eventType.classNames,
              allDay: eventType.allDay,
              // display: 'background',
              color: eventType.color,
              backgroundColor: eventType.color,
            });
          });
        });

        this.setState({
          loading: false,
          currentEvents: events,
        });

      })
      .catch(error => {
        //this.setState({ loading: false, error })
        console.log(error)
      })
      .finally(() => {
        // always executed
        // this.setState({ loading: false }) // do not modify state to offten because it re-renders and it takes time
      });
  }

  render() {
    const intl = this.intl;

    if (this.state.currentEvents.length === 0) {
      return null;
    }

    return (
      <div className='demo-app'>
        {/* {this.renderSidebar()} */}
        <div className='demo-app-main'>
          
          <p className="_has-text-align-center has-medium-font-size mb-0">
            <strong>{this.intl.locale === `pl` ? `Kalendarz wydarzeń:` : `Events calendar:`}</strong>
          </p>

          <div className="row s4n-calendar-container">
            <div className="col-12 _col-md-6">
              <FullCalendar
                // timeZone={'UTC'}
                locale={intl.locale === `pl` ? localePl : localeEn}
                plugins={[
                  // dayGridPlugin, 
                  // timeGridPlugin, 
                  listPlugin, 
                  interactionPlugin,
                ]}
                headerToolbar={{
                  left: 'title',
                  center: '',
                  right: 'prev,next today'
                  // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                initialView='listMonth'
                // editable={true}
                // selectable={true}
                // selectMirror={true}
                weekends={this.state.weekendsVisible}
                
                // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                initialEvents={this.state.currentEvents} // alternatively, use the `events` setting to fetch from a feed

                // select={this.handleDateSelect}
                // eventContent={(eventInfo) => { // custom render function
                //   console.log(eventInfo)
                //   return (
                //     <>
                //       {/* <b>{eventInfo.timeText}</b> */}
                //       {/* <i>{eventInfo.event.title}</i> */}
                //       <Link to={eventInfo.event.url} className="btn btn-primary w-100">{eventInfo.event.title}</Link>
                //       {/* <button className="btn btn-primary w-100">{eventInfo.event.title}</button> */}
                //       {/* {eventInfo.event.title} */}
                //     </>
                //   )
                // }}
                // eventBackgroundColor={(p) => {
                //   console.log(p)
                // }}
                // dayCellContent={(p) => {
                //   console.log(p)
                // }}
                // eventDisplay={(eventInfo) => { // custom render function
                //   console.log(eventInfo)
                //   return (
                //     <>
                //       +
                //     </>
                //   )
                // }}
                // eventClick={this.handleEventClick}
                eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
                */
                


                // dateClick={this.handleDateClick}
                // events={this.state.currentEvents}

                dayMaxEventRows={true} // adjust to 6 only for timeGridWeek/timeGridDay
                dayMaxEvents={true} // allow "more" link when too many events
                // moreLinkText={intl.formatMessage({ id: `soft4net_more` })}
                moreLinkText={``}
//                 moreLinkContent={(num) => {
// // console.log(num.view)
// console.log(num.view.getCurrentData())
//                   return <>aaaaaa</>;
//                 }}

                moreLinkContent={(arg) => {
                  return ``;
                  // console.log(arg)
                  // console.log(arg.view.getCurrentData())
                }}
                moreLinkDidMount={(arg) => {
// console.log(arg)
                  const { el: element } = arg;
                  const eventTypeColor = element?.parentNode?.previousSibling?.children[0]?.children[0]?.style?.borderColor;
                  // element.style.backgroundColor = eventTypeColor;
                  element.closest('.fc-day').style.backgroundColor = eventTypeColor;
                  // arg.el.textContent = arg.el.closest('.fc-day').dataset.date
                }}

                themeSystem={`bootstrap`}
                // eventTimeFormat
                // eventColor
                // eventBackgroundColor="#0f0"

              />
            </div>
            <div className="col-12 _col-md-6">
              <ul className="calendar-legend">
                {Object.keys(this.state.event_types).map(eventTypeKey => {
                  const fieldTypeAllowedValue = this.state.event_types[eventTypeKey];
                  const nameAndColor = fieldTypeAllowedValue.split(`_`);

                  const eventType = {
                    type_title: nameAndColor[0],
                    color: nameAndColor[1],
                    classNames: `harmonogram` === eventTypeKey ? `harmonogram` : `custom-event-${eventTypeKey}`,
                  }

                  return (
                    <li>
                      <i className="fas fa-circle" style={{color: eventType.color}}></i> 
                      <span className={eventType.classNames}>{eventType.type_title}</span>
                    </li>
                  );
                })}
              </ul>              
            </div>

          </div>

        </div>
      </div>
    )
  }

/*
  renderSidebar() {
    return (
      <div className='demo-app-sidebar'>
        <div className='demo-app-sidebar-section'>
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className='demo-app-sidebar-section'>
          <label>
            <input
              type='checkbox'
              checked={this.state.weekendsVisible}
              onChange={this.handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>
            {this.state.currentEvents.map(renderSidebarEvent)}
          </ul>
        </div>
      </div>
    )
  }
*/

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    })
  }

  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect() // clear date selection

    // calendarApi.popover();

    return;
/*
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
*/
  }

  handleEventClick = (clickInfo) => {
// console.log(clickInfo)
    // const calendarApi = clickInfo.view.calendar
    const { 
      el: element, 
      event, 
      view: { calendar: calendarApi } 
    } = clickInfo;

    // window.jQuery(element).tooltip({title: event.title});

    // calendarApi.popover();

    // element.popover({
    //   animation: true,
    //   delay: 300,
    //   content: '<b>Inicio</b>:' + event.start + "<b>Fin</b>:" + event.end,
    //   trigger: 'hover'
    // });

    return;
/*
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    }
    
    clickInfo.event.remove()
*/
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    })
  }

  handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  }

}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
      <i>{event.title}</i>
    </li>
  )
}

export default injectIntl(Component)






/*

document.addEventListener('DOMContentLoaded', function() {
    var Calendar = FullCalendar.Calendar;
    var Draggable = FullCalendar.Draggable;
  
    var containerEl = document.getElementById('external-events');
    var calendarEl = document.getElementById('calendar');
    var checkbox = document.getElementById('drop-remove');
  
    // initialize the external events
    // -----------------------------------------------------------------
  
    new Draggable(containerEl, {
      itemSelector: '.fc-event',
      eventData: function(eventEl) {
        return {
          title: eventEl.innerText
        };
      }
    });
  
    // initialize the calendar
    // -----------------------------------------------------------------
  
    var calendar = new Calendar(calendarEl, {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
      initialView: 'dayGridMonth',
      editable: true,
      droppable: true, // this allows things to be dropped onto the calendar
      drop: function(info) {
        // is the "remove after drop" checkbox checked?
        if (checkbox.checked) {
          // if so, remove the element from the "Draggable Events" list
          info.draggedEl.parentNode.removeChild(info.draggedEl);
        }
      }
    });
  
    calendar.render();
});

*/
